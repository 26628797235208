import React, {useState, useEffect} from 'react';
import { PropsHeaderRecipe } from './interface';
import { ContentHeader, shareStyles } from './styles';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Drawer, IconButton, Box, Typography, Grid, Snackbar, Alert } from '@mui/material';
import { EmailShareButton, EmailIcon, WhatsappShareButton, WhatsappIcon, TelegramShareButton, TelegramIcon } from 'react-share';
import WaletoIcon from '../header/WaletoDark.svg';

const Header = ({ emisor, fechaEmision, nroReceta, hash }: PropsHeaderRecipe) => {

  const [openDrawer, setOpenDrawer] = useState(false)
  const [urlRecipe, setUrlRecipe] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  
  const getDateFormated = (date: string) => {
    return (
      date.substring(8, 10) +
      "/" +
      date.substring(5, 7) +
      "/" +
      date.substring(0, 4)
    );
  }

  useEffect(() => {
    setUrlRecipe(
      `${window.location.href}`)
  }, [])

  const handleToggleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(urlRecipe)
    handleToggleDrawer()
    setOpenSnackbar(true)
  }

  const handleWaletoButtonClick = () => {
    try {

      const androidPackageName = "com.ar.innovamed.waleto";
      const iOSBundleId = "com.ar.innovamed.waleto";
  
      const isAndroid = /Android/i.test(navigator.userAgent);
      const isiOS = /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
  
      if (isAndroid) {
        document.location = `waletoapp://verum/${hash}`;
        setTimeout(() => {
          window.location.href = `market://details?id=${androidPackageName}`;
        }, 1000);
      } else if (isiOS) {
        document.location = `waletoapp://verum/${hash}`;
        setTimeout(() => {
          window.location.href = `itms-apps://itunes.apple.com/app/${iOSBundleId}`;
        }, 1000);
      } else {
        window.open("https://app.dev.waleto.com.ar/front-credenciales/verum/" + hash);
      }
    } catch (error) {
      console.error('Error handling deep link:', error);
    }
  };

  
  return (
    <ContentHeader>
      <p className="Title">Prescripción</p>
      <div className="DataRecipe">
        <p>
          <span className="Tag">Emisor:</span>{" "}
          {emisor !== "" ? emisor : "No reconocido"}
        </p>
        {nroReceta !== 0 && (
          <div className="DataRecipeVerificacion">
            <IconButton
              onClick={handleToggleDrawer}
            >
              <ShareIcon
                sx={{ color: "black" }}
              />
            </IconButton>
            <p>
              <span className="Tag">Verificación:</span>{" "}
            </p>
            <CheckCircleIcon sx={{ color: "#18C101" }} />
          </div>
        )}
        {nroReceta === 0 && (
          <div className="DataRecipeVerificacion">
            <p>
              <span className="Tag">No válido:</span>{" "}
            </p>
            <CancelIcon sx={{ color: "#F62121" }} />
          </div>
        )}
      </div>
      <div className="DataRecipe">
        <p>
          {nroReceta != 0 && (
            <div>
              <span className="Tag">Nro. Orden:</span> {nroReceta.toString()}
            </div>
          )}
          {nroReceta == 0 && (
            <div>
              <span className="Tag">Nro. Orden:</span> Inexistente
            </div>
          )}
        </p>
        <p>
          {" "}
          <span className="Tag">Fecha:</span>{" "}
          {fechaEmision && fechaEmision !== "" ? fechaEmision : " - "}
        </p>
      </div>
      <Drawer
        anchor="bottom"
        open={openDrawer}
        onClose={handleToggleDrawer}
      >
        <Box
          sx={shareStyles.shareBox}
        >
          <Typography variant="h6" component="div"
            sx={{ marginBottom: "2rem" }}
          >
            Compartir Receta Mediante:
          </Typography>
          <Grid container spacing={2}>
            <Grid 
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              xs={3}
            >
              <EmailShareButton
              url={urlRecipe}
              subject={`Receta Compartida Nro ${nroReceta}`}
              body={`Receta Compartida Nro ${nroReceta}`}
              >
                <EmailIcon size={shareStyles.iconSize} round={true} />
              </EmailShareButton>
              <Typography variant="subtitle1" component="div"
                align='center'
              >
                Email
              </Typography>
            </Grid>
            <Grid 
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              xs={3}
            >
              <WhatsappShareButton
                url={urlRecipe}
                title={`Receta Compartida Nro ${nroReceta}`}

              >
                <WhatsappIcon size={shareStyles.iconSize} round={true} />
              </WhatsappShareButton>
              <Typography variant="subtitle1" component="div"
                align='center'
              >
                Whatsapp
              </Typography>
            </Grid>
            <Grid 
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              xs={3}
            >
              <TelegramShareButton
                url={urlRecipe}
                title={`Receta Compartida Nro ${nroReceta}`}
              >
                <TelegramIcon size={shareStyles.iconSize} round={true} />
              </TelegramShareButton>
              <Typography variant="subtitle1" component="div"
                align='center'
              >
                Telegram
              </Typography>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              xs={3}
            >
              <IconButton onClick={() => handleWaletoButtonClick()} sx={shareStyles.boxCopyButton}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    alt="Waleto"
                    src={WaletoIcon}
                    style={{ width: shareStyles.iconSize, height: shareStyles.iconSize, marginTop: '16px', marginRight: '20px' }}
                  />
                </div>
              </IconButton>
              <Typography variant="subtitle1" component="div" align="center" sx={{ marginBottom: '23px', marginRight: '20px', xs: 'm' }}>
                Waleto
              </Typography>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              xs={3}
            >
              <IconButton
                onClick={handleCopyUrl}
                sx={shareStyles.boxCopyButton}
              >
                <ContentCopyIcon
                  sx={shareStyles.copyButton}
                />
              </IconButton>
              <Typography variant="subtitle1" component="div"
                align='center'
              >
                Copiar Enlace
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
      <Snackbar
        anchorOrigin={{'horizontal': 'center', 'vertical': 'top'}}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          variant='filled'
          severity='info'
        >
          Enlace copiado al portapapeles
        </Alert>
      </Snackbar>

    </ContentHeader>
  );
}

export default Header;
